export const environment = {
  baseEndpoint: 'https://api-dev.talkingstick.app/',
  buildNumber: "28962313",  
  versionNumber: "3.1.0",  
  environment_deploy: 'dev',
  rayGunKey: 'hqaZDpkENyp0WOVqpDhdg',
  authResultKey: '41cfd863-d3be-4e1c-84fe-1a6d71f620c0',
  cryptoKey: '0ef6fc19-fa40-476e-8c9c-dbc22969acab',
  posthogToken: 'phc_FxfNWKWqYxa9nm24KsWccHzMR776akapxZWqhHjFTGJ',
  orgCode: 'burns',
  appCode: 'tbw',
  locizeProjectId: '714c2f9a-6c84-4052-b7f0-1e6469e617d7',
  appStoreUrls: {
    sk: {
      ios: 'itms-beta://beta.itunes.apple.com/v1/app/1610784906',
      androidPlay: 'https://play.google.com/store/apps/details?id=app.talkingstick',
      androidMarket: 'market://details?id=app.talkingstick',
    },
    burns: {
      ios: 'itms-beta://beta.itunes.apple.com/v1/app/6670217457',
      androidPlay: 'https://play.google.com/store/apps/details?id=app.theburnsway',
      androidMarket: 'market://details?id=app.theburnsway',
    },
  },

  firebaseConfig: {
    apiKey: 'AIzaSyDye1Ufb56MVrkePYPwHUj27A0q07xypKk',
    authDomain: 'the-burns-way-dev.firebaseapp.com',
    projectId: 'the-burns-way-dev',
    storageBucket: 'the-burns-way-dev.appspot.com',
    messagingSenderId: '160913184471',
    appId: '1:160913184471:web:2ff2a7a1f1bf33fe3ac292',
    vapidKey: 'BC1KH3H0nS9B8_NV6SPNvzE88QOWEDFx_HIbz7wWPh6CHW8hTkqyLHz79EfqArfbZXRDVCLhn4g7mfU8cRlpA9k',
  },
};
